import React from "react";
import { Layout, SEO, Slider } from "../../components";
import database from "../../config/database.json";
import _ from "styled-components";

import ServicesList from "../services/list";

const HomePage = () => {
  const { highlights } = database;
  
  return (
    <Layout>
      <SEO title="Alt Laser, Curitiba, Paraná" />
      <Slider />
      <Wrapper className="uk-container uk-margin-large-top uk-margin-large-bottom">
        <h1 className="title">Olá nós somos a Alt Laser!</h1>
        <h2 className="subtitle">Manutenção Preditiva / Preventiva</h2>
        <div className="uk-child-width-expand uk-margin-top" data-uk-grid>
          {highlights.map((e, index) => (
            <div className="uk-width-1-3@m" key={index}>
              <div className="uk-flex uk-column-1-2">
                <div className="uk-width-1-4 uk-margin-right">
                  <i data-uk-icon="icon:nut;ratio:2" />
                </div>
                <div>
                  <strong>{e.title}</strong>
                  <p>{e.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Wrapper>
      <Background>
        <div className="uk-container">
          <h2 className="title">Atuação técnica 100% especializada</h2>
          <p>
            A Alt Laser atua em todo o território nacional, com maior presença na região sul do país. Fornecendo soluções eficazes através de serviços de:
          </p>
          <ServicesList />
        </div>
      </Background>
    </Layout>
  );
};

const Wrapper = _.div`
  .title {
    font-size: 3rem;
    text-align: center;
    line-height: 3.5rem;
    font-weight: 500;
    margin: 0;
    color: #213767;

    @media(max-width: 640px) {
      font-size: 2.25rem;
    }
  }

  .subtitle {
    font-size: 2rem;
    color: #CA2525;
    text-align: center;
    margin: 0 0 3rem 0;

    @media(max-width: 640px) {
      font-size: 1.5rem;
    }
  }

  strong {
    color: #213767;
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.rem;
  }

  @keyframes highlight {
    0%   { transform: scale(1) }
    70%  { transform: scale(1.15) rotate(90deg) }
    100% { transform: scale(1) rotate(180deg) }
  }

  .uk-column-1-2:hover {
    i {
      color: #CA2525;
      transform: scale(1);
	    animation: highlight 1s; 
    }
  }
`;

const Background = _.div`
  padding: 2.5em 0;
  background: rgb(248,250,250);
  background: linear-gradient(180deg, rgba(248,250,250,1) 0%, rgba(255,255,255,1) 100%);
  text-align: center;

  .title {
    font-size: 2.65rem;
    font-weight: 500;
    margin: 0;
    color: #213767;

    @media(max-width: 640px) {
      font-size: 2rem;
    }
  }
`;
export default HomePage;
