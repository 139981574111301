import React from "react";
import { Link } from "react-router-dom";
import database from "../../config/database.json";
import _ from "styled-components";

const Services = () => {
  const { services } = database;

  return (
    <Wrapper className="uk-child-width-expand uk-margin-large-top" data-uk-grid>
      {services.map((e, index) => (
        <div className="uk-width-1-2 uk-width-1-4@m" key={index}>
          <Link to={e.url} title={e.name}>
            <img src={e.image} alt={e.name} data-uk-img />
            <strong className="uk-display-block">{e.name}</strong>
          </Link>
        </div>
      ))}
    </Wrapper>
  );
};

const Wrapper = _.div`
  img {
    border: 5px solid rgb(248,250,250);
    margin-bottom: .75rem;
    border-radius: 48px;
    max-width: 144px;
  }

  a { 
    color: #213767;

    &:hover {
      color: #CA2525;
      text-decoration: none;
      transition: .5s;

      img { 
        border-color: #CA2525;
        transition: .3s;
      }
    }
  }
`;

export default Services;
