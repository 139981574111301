import React from "react"
import { Link } from "react-router-dom";
import _ from "styled-components"

const Breadcrumb = ({ title, path = "/" }) => (
  <Wrapper>
    <div className="uk-container uk-flex uk-flex-between">
      <Title className="uk-text-truncate">{title}</Title>
      <nav className="uk-visible@m">
        <Link to="/">Página inicial</Link>
        <Link to={path} className="active">{title}</Link>
      </nav>
    </div>
  </Wrapper>
)

const Wrapper = _.div`
  background-color: #213767;
  height: 3.75rem;
  margin-bottom: 1.75rem;

  nav {
    margin-top: 1.1rem;
  }

  nav a {
    font-size: 1rem;
    display: inline-block;
    margin-left: 1rem;
    color: rgba(255, 255, 255, .75);
  }

  .active { color: #CA2525 }

  @media(max-width: 640px) {
    nav a { font-size: .85rem } 
  }
`

const Title = _.h1`
  background-color: #CA2525;
  padding: 1rem;
  display: inline-block;
  
  color: white;
  font-size: 1.5rem;

  @media(max-width: 640px) {    
    font-size: 1.5rem;
    width: 100%;
  }
`

export default Breadcrumb
