import React from "react";
import { Container } from ".";
import { Link } from "react-router-dom";
import database from "../config/database.json";
import _ from "styled-components";

const Header = () => {
  const { title, navbarLinks } = database;

  return (
    <Wrapper data-uk-sticky>
      <Container>
        <div className="uk-flex uk-flex-between">
          <div>
            <Link to="/" title={title}>
              <img src="/images/altlaser-logo.png" data-uk-img alt={title} />
            </Link>
          </div>
          <div className="uk-flex uk-flex-middle uk-visible@m">
            <Navbar className="uk-flex uk-flex-middle">
              {navbarLinks &&
                navbarLinks.map((link, index) => (
                  <Link to={link.page} title={link.title} key={index}>
                    {link.title}
                  </Link>
                ))}
            </Navbar>
          </div>
          <div className="uk-flex uk-flex-middle uk-hidden@m">
            <Button data-uk-toggle="target: #mobile-navbar" type="button">
              <Icon data-uk-icon="icon:menu; ratio:1.75" />
            </Button>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = _.header`
  padding: 1em 0;
  background-color: white;

  img { max-width: 165px }

  @media(max-width:640px) {
    img { max-width: 145px }
  }
`;

const Navbar = _.nav`
  a {
    font-size: .90em;
    font-weight: bold;
    margin-left: 24px;
    text-transform: uppercase;
    color: #213767;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover, &:focus {
      color: #ca2525;
    }
  }
`;

const Icon = _.i`
  color: #213767;
`;

const Button = _.button`
  background-color: transparent;
  border: none;
`;

export default Header;
