import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./uikit.min.css";
import "./index.css";

import { Routes } from "./config";
import * as serviceWorker from "./serviceWorker";

const Website = () => (
  <BrowserRouter>
      <Routes />
  </BrowserRouter>
);

const website = document.getElementById("root");

website.hasChildNodes()
  ? hydrate(<Website />, website)
  : render(<Website />, website);

serviceWorker.unregister();