import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Topbar, Header, MobileNavbar, Footer } from ".";

const Layout = ({ children }) => (
  <Fragment>
    <Topbar />
    <Header />
    <MobileNavbar />
    {children}
    <Footer />
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
