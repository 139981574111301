import React from "react";
import database from "../config/database.json";
import _ from "styled-components";

const Slider = () => {
  const { sliders } = database;
  
  return (
    <Slideshow data-uk-slideshow="animation:slide;autoplay:true;max-height:520">
      <div className="uk-light">
        <ul className="uk-slideshow-items">
          {sliders.map((item, index) => (
            <SliderItem {...item} key={index} />
          ))}
        </ul>
        <button
          className="uk-control uk-position-center-left uk-position-small uk-visible@m"
          data-uk-slidenav-previous
          data-uk-slideshow-item="previous"
        ></button>
        <button
          className="uk-control uk-position-center-right uk-position-small uk-visible@m"
          data-uk-slidenav-next
          data-uk-slideshow-item="next"
        ></button>
      </div>
    </Slideshow>
  );
};

const SliderItem = ({ strong, heading, span, source }) => (
  <li>
    <div className="uk-position-cover">
      <img src={source} alt={heading} data-uk-cover />
      <div className="uk-overlay uk-position-center">
        <strong>{strong}</strong>
        <h2>{heading}</h2>
        <span>{span}</span>
      </div>
    </div>
  </li>
);

const Slideshow = _.div`
  position: relative;

  .uk-overlay {
    width: 640px;
    left: 32%;
  }


  .uk-overlay h2 {
    margin: 12px 0;
    font-size: 3.5rem;
    line-height: 3.5rem;
    font-weight: 700;
  }

  .uk-overlay strong {
    color: #ca2525;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  .uk-overlay span {
    font-size: 1.25rem;
  }

  @media(max-width: 640px) {
    .uk-overlay {
      left: 50%;
    }

    .uk-overlay strong {
      font-size: .85rem;
    }

    .uk-overlay h2 {
      font-size: 1.85rem;
      line-height: 1.8rem;
      margin: 8px 0;
    }

    .uk-overlay span {
      font-size: .75rem;
      line-height: .5rem !important;
    }
  }
`;

export default Slider;
