import React from "react";
import { Container, ProtoLink } from ".";
import database from "../config/database.json";
import _ from "styled-components";

const Topbar = () => {
  const { title, webmail, phone } = database;

  return (
    <Wrapper>
      <Container>
        <div className="uk-column-1-1@s uk-column-1-2@m uk-column-1-2@l">
          <div className="uk-visible@m">
            <Icon data-uk-icon="icon:location; ratio:.75" />
            <span>{title}</span>
          </div>
          <div>
            <div className="uk-flex uk-flex-between uk-flex-right@m">
              <div className="uk-margin-right">
                <Icon data-uk-icon="icon:mail; ratio:.75" />
                <a
                  href={webmail}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Webmail"
                >
                  Webmail
                </a>
              </div>
              <div>
                <Icon data-uk-icon="icon:receiver; ratio:.75" />
                <ProtoLink
                  type="tel"
                  label={phone}
                  source={`+55${phone.replace(/\D/gi, "")}`}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = _.div`
  background-color: #213767;
  color: rgba(255,255,255,0.85);
  padding: 12px 0;
  font-size: .85em;

  a { color: rgba(255,255,255,0.85); &:hover { color: white }}
`;

const Icon = _.i`
  margin-right: 8px;
  color: white;
`;

export default Topbar;
