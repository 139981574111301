import React from "react";
import { Link } from "react-router-dom";
import { ProtoLink } from ".";
import database from "../config/database.json";
import _ from "styled-components";

const Footer = () => {
  const { title, phone, email, address, navbarLinks, social } = database;

  return (
    <Wrapper>
      <div className="uk-container">
        <div data-uk-grid className="uk-child-width-expand">
          <div className="uk-width-1-1 uk-width-1-3@m">
            <div className="uk-margin-top uk-margin-bottom">
              <div className="uk-flex uk-child-width-expand">
                <div className="uk-width-1-6">
                  <Icon
                    data-uk-icon="icon:receiver;ratio:.85"
                    className="uk-margin-small-right"
                  />
                </div>
                <h4>
                  <ProtoLink
                    type="tel"
                    label={phone}
                    source={`+55${phone.replace(/\D/gi, "")}`}
                  />
                </h4>
              </div>

              <div className="uk-flex uk-child-width-expand uk-margin-top">
                <div className="uk-width-1-6">
                  <Icon
                    data-uk-icon="icon:location;ratio:.85"
                    className="uk-margin-small-right"
                  />
                </div>
                <address>{address}</address>
              </div>

              <div className="uk-margin-top">
                <p>O seu contato é importante para nós. Deixe-nos ajudá-lo.</p>
              </div>
            </div>
          </div>

          <div className="uk-width-1-1 uk-width-1-3@m">
            <nav className="uk-margin-top">
              <h3>Links rápidos</h3>
              {navbarLinks &&
                navbarLinks.map((link, index) => (
                  <Link to={link.page} title={link.title} key={index}>
                    <Icon data-uk-icon="icon:link;ratio:.85" /> {link.title}
                  </Link>
                ))}
            </nav>
          </div>

          <div className="uk-width-1-1 uk-width-1-3@m">
            <div className="uk-margin-top">
              <h3>Alt Laser Manutenção</h3>
              <p>
                Entre em contato com a <strong>Alt Laser Manutenção</strong> pelo
                e-mail <ProtoLink type="mailto" label={email} source={email} />,
                ou se preferir{" "}
                <Link
                  to="/fale-conosco"
                  className="proto-link"
                  title="Entre em contato"
                >
                  clique aqui
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-flex uk-flex-between">
          <div>
            <nav className="social-navbar">
              {social &&
                social.map((link) => (
                  <a href={link.url} key={link.name} title={link.name}>
                    <Icon data-uk-icon={`icon:${link.name}`} />
                  </a>
                ))}
            </nav>
          </div>
          <div className="uk-flex uk-flex-middle">
            <small>{title}</small>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = _.footer`
  background-color: #213767;
  color: #BDD9F4;
  padding: 2rem 0;

  h3 {
    color: white;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  nav a {
    display: block;
    line-height: 2.5rem;
    color: #BDD9F4;
  }

  .proto-link {
    color: white;
    text-decoration: none;
  }

  h4, address { margin: 0 }

  .social-navbar a {
    display: inline-block;
    margin-right: 16px;
  }

  hr {
    border-color: #CA2525;
    margin: 2rem 0;
  }
`;

const Icon = _.i`
  color: white;
`;

export default Footer;
