import React from "react";
import { Layout, SEO, Breadcrumb } from "../../components";
import _ from "styled-components";

const AboutPage = () => (
  <Layout>
    <SEO title="Alt Laser | Quem Somos" />
    <Breadcrumb title="Quem somos" path="quem-somos" />
    <Wrapper className="uk-container uk-margin-large-top uk-margin-large-bottom">
      <div className="uk-child-width-expand" data-uk-grid>
        <div>
          <h2>Atuação ética e compromisso legal</h2>
          <p>
            A ALT LASER Manutenção Preditiva, oferece a elaboração e execução de
            contratos personalizados de manutenção preditiva para o controle dos
            equipamentos da sua instalação. Esse contrato abrange a catalogação
            quantitativa e qualitativa do universo de motores elétricos e
            equipamentos da unidade fabril.
          </p>

          <h2>Nossos valores</h2>
          <p>
            Nossos valores definem quem somos. Eles são os pilares fundamentais
            de nossa organização, orientando nossas ações e comportamento:
          </p>

          <ul>
            <li className="uk-child-width-expand" data-uk-grid>
              <div className="uk-width-1-6">
                <i data-uk-icon="icon:cog;ratio:2.5"></i>
              </div>
              <div>
                <h3>Resultados Sólidos</h3>
                <p>
                  Garantir resultados sólidos e sustentáveis para clientes,
                  sociedade, colaboradores e acionistas através do
                  desenvolvimento de pessoas e processos industriais.
                </p>
              </div>
            </li>
            <li className="uk-child-width-expand" data-uk-grid>
              <div className="uk-width-1-6">
                <i data-uk-icon="icon:star;ratio:2.5"></i>
              </div>
              <div>
                <h3>Somos Referência</h3>
                <p>
                  A Alt Laser é uma empresa referência manutenção industrial no
                  aspecto: técnico, pessoal e profissional. Contribuindo sempre
                  na execução de manutenção preditiva e preventiva.
                </p>
              </div>
            </li>
            <li className="uk-child-width-expand" data-uk-grid>
              <div className="uk-width-1-6">
                <i data-uk-icon="icon:settings;ratio:2.5"></i>
              </div>
              <div>
                <h3>Alta Qualidade</h3>
                <p>
                  Ofertamos produtos e serviços de alto padrão, com qualidade
                  mundial em medição e precisão, atendendo todas as necessidades
                  do moderno mundo industrial.
                </p>
              </div>
            </li>
          </ul>

          <p>
          Alt Laser fornece soluções através de serviços e produtos inovadores, com foco em aplicações para a indústria 4.0. Especializados em análise de vibração de máquinas, termografia, balanceamento, alinhamento a laser, MCA , ESA, ODS e treinamentos.
          </p>
        </div>
        <div className="uk-width-1-3@m">
          <div className="box uk-margin-large-bottom">
            <h3>Os nossos valores definem quem somos!</h3>
            <p>
              Nossa crença na ideia de que todos temos um papel a desempenhar na
              construção de um mundo mais justo, sustentável e livre da
              corrupção. Apoie o projeto de lei do MPF no link abaixo:
            </p>
            <a
              href="http://www.dezmedidas.mpf.mp.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.dezmedidas.mpf.mp.br/
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
    <Background>
      <div className="uk-container">
        <h2 className="title">Nossos clientes e Parceiros</h2>
        <p className="uk-margin-bottom">
          Empresas, fornecedores e parceiros que confiam em nosso trabalho.
        </p>
      </div>
    </Background>
  </Layout>
);

const Wrapper = _.div`
  h2, h3 {
    color: #213767;
    font-weight: 700;
  }

  h3 { font-weight: 500 }

  @media(max-width: 640px) {
    h3 { font-size: 1.25rem }
    i  { margin-top: .5rem }
  }

  @keyframes highlight {
    0%   { transform: scale(1) }
    70%  { transform: scale(1.5) }
    100% { transform: scale(1) }
  }

  li:hover {
    i {
      color: #CA2525;
      transform: scale(1);
	    animation: highlight 1s; 
    }
  }

  .box, .highlight {
    padding: 2rem 1.5rem;
    text-align: center;
  }

  .box {
    border: 1px solid #d9d9d9;    
    background: url('/images/about-us-box.png') no-repeat center bottom;
  }

  .highlight {
    h3 { color: white }

    background-color: #213767;
    color: rgba(255, 255, 255, .65);

    .uk-button-default { color: white }
  }
`;

const Background = _.div`
  padding: 2.5em 0;
  background: rgb(248,250,250);
  background: linear-gradient(180deg, rgba(248,250,250,1) 0%, rgba(255,255,255,1) 100%);
  text-align: center;

  .title {
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0;
    color: #213767;

    @media(max-width: 640px) {
      font-size: 2rem;
    }
  }
`;

export default AboutPage;
