
import React from "react";
import { Layout, SEO, Breadcrumb } from "../../components";
import _ from "styled-components";

import ServicesList from "../services/list";

const ServicesPage = () => (
  <Layout>
    <SEO title="SOLID Consulting | Serviços" />
    <Breadcrumb title="Serviços" path="servicos" />
    <Background>
      <div className="uk-container">
        <h2 className="title">Como podemos ajudar?</h2>
        <strong className="bold">Serviços prestados pela Alt Laser</strong>
        <ServicesList />
      </div>
    </Background>
  </Layout>
);

const Background = _.div`
  padding: 2.5em 0;
  text-align: center;

  .title {
    font-size: 2.65rem;
    font-weight: 500;
    margin: 0;
    color: #213767;

    @media(max-width: 640px) {
      font-size: 2rem;
    }
  }

  .bold {
    color: #CA2525;
    font-size: 1.5rem;
  }
`;

export default ServicesPage;
