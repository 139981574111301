import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Layout, SEO, Breadcrumb } from "../../components";
import database from "../../config/database.json";
import _ from "styled-components";

const ServicePage = () => {
  const [service, setService] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const service = database.services.find((e) => e.url === `/servicos/${id}`);
    if (service) setService(service);
  }, [id]);

  if (!service.topics) return null;

  return (
    <Layout>
      <SEO title="Alt Laser | Serviços" />
      <Breadcrumb title="Serviços" path="/servicos" />
      <Wrapper className="uk-container uk-margin-top uk-margin-large-bottom">
        <div className="uk-child-width-expand" data-uk-grid>
          <div>
            <h2>{service.name}</h2>
            <p dangerouslySetInnerHTML={{ __html: service.text }}></p>
            <ul>
              {service &&
                service.topics.map((e, index) => (
                  <li key={index}>
                    <strong>{e}</strong>
                  </li>
                ))}
            </ul>
            <p dangerouslySetInnerHTML={{ __html: service.extra }}></p>
          </div>
          <div className="uk-visible@m uk-width-1-4">
            <center>
              <img src={service.image} alt={service.name} />
            </center>
          </div>
        </div>
        <Link to="/servicos">Outros serviços</Link>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = _.div`
  h2, h3 {
    color: #163962;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  h3 { font-weight: 500 }
  li a { color: #BF9234 }
`;

export default ServicePage;
