import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import {
  HomePage,
  AboutPage,
  ServicesPage,
  ServicePage,
  ContactPage,
} from "../pages";

const Routes = ({ history }) => {
  useEffect(() => {
    const listener = history.listen(() =>
      window.scrollTo({ top: 0, behavior: "smooth" })
    );

    return () => listener();
  }, [history]);

  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/quem-somos" component={AboutPage} />
      <Route exact path="/servicos" component={ServicesPage} />
      <Route exact path="/servicos/:id" component={ServicePage} />
      <Route exact path="/fale-conosco" component={ContactPage} />
    </Switch>
  );
};

export default withRouter(Routes);
