import React from "react"
import _ from "styled-components"

const Container = ({ children }) => (
  <Wrapper className="uk-container">{children}</Wrapper>
)

const Wrapper = _.div``

export default Container
