import React, { useState } from "react";
import database from "../../config/database.json";
import { Layout, SEO, Breadcrumb } from "../../components";
import _ from "styled-components";

const ContactPage = () => {
  const { address } = database;

  const [params, setParams] = useState({});

  return (
    <Layout>
      <SEO title="SOLID Consulting | Fale conosco" />
      <Breadcrumb title="Fale conosco" path="fale-conosco" />
      <Wrapper className="uk-container uk-margin-large-top uk-margin-large-bottom">
        <div className="uk-child-width-expand" data-uk-grid>
          <div>
            <h2>Estamos aguardando por você!</h2>
            <p>
              Preencha o formulário abaixo, e entre em contato com nossa equipe.
            </p>

            <form className="uk-child-width-expand uk-grid-small" data-uk-grid>
              <div className="uk-width-1-1">
                <label htmlFor="company">Empresa/Razão social</label>
                <input
                  name="company"
                  className="uk-input"
                  onChange={(e) =>
                    setParams({ ...params, [e.target.name]: e.target.value })
                  }
                  placeholder="Minha empresa Ltda."
                />
              </div>
              <div className="uk-column-1-2 uk-margin-small-top">
                <div>
                  <label htmlFor="email">E-mail</label>
                  <input
                    name="email"
                    className="uk-input"
                    onChange={(e) =>
                      setParams({ ...params, [e.target.name]: e.target.value })
                    }
                    placeholder="exemplo@exemplo.com.br"
                  />
                </div>
                <div>
                  <label htmlFor="phone">Telefone</label>
                  <input
                    name="phone"
                    className="uk-input"
                    onChange={(e) =>
                      setParams({ ...params, [e.target.name]: e.target.value })
                    }
                    placeholder="Informe o telefone"
                  />
                </div>
              </div>
              <div className="uk-width-1-1">
                <label htmlFor="subject">Assunto/Departamento</label>
                <input
                  name="subject"
                  className="uk-input"
                  onChange={(e) =>
                    setParams({ ...params, [e.target.name]: e.target.value })
                  }
                  placeholder="Minha empresa Ltda."
                />
              </div>
              <div className="uk-width-1-1">
                <label htmlFor="message">Escreva sua mensagem</label>
                <textarea
                  className="uk-textarea"
                  name="message"
                  onChange={(e) =>
                    setParams({ ...params, [e.target.name]: e.target.value })
                  }
                ></textarea>
              </div>

              <div className="uk-width-1-1">
                <button type="submit" className="uk-button uk-button-primary">
                  Enviar
                </button>
              </div>
            </form>
          </div>
          <div className="uk-width-1-1 uk-width-1-3@m">
            <h2>Localização</h2>
            <address>{address}</address>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.14911056238!2d-49.291104384985125!3d-25.46669598377152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce36359d0e52d%3A0xabb859a6d6e7c78e!2sR.%20Acre%2C%20270%20-%20%C3%81gua%20Verde%2C%20Curitiba%20-%20PR%2C%2080620-040!5e0!3m2!1sen!2sbr!4v1591747450383!5m2!1sen!2sbr"
              width="100%"
              height="320px"
              title="Nossa Localização"
            ></iframe>
            <p>
              Clique sobre o endereço para ver nossa localização no Google Maps.
            </p>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = _.div`
  h2, h3 {
    color: #213767;
    font-weight: 700;
  }

  h3 { font-weight: 500 }

  label {
    margin-bottom: .5rem;
    display: block;
  }

  .uk-button-primary {
    background-color: #213767;
  }

  .uk-input, .uk-textarea {
    font-weight: bold;
    color: #213767;
  }
`;

export default ContactPage;
