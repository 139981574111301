import React from "react"

const ProtoLink = ({ type, source, label }) => (
  <a
    href={`${type}:${source}`}
    className="proto-link"
    title="Contate a SOLID Consulting">
    {label}
  </a>
)

export default ProtoLink
