import React from "react";
import { Link } from "react-router-dom";
import database from "../config/database.json";
import _ from "styled-components";

const MobileNavbar = () => {
  const { title, navbarLinks } = database;
  
  return (
    <Wrapper id="mobile-navbar" data-uk-offcanvas>
      <Panel className="uk-offcanvas-bar">
        <Link to="/" title={title}>
          <img src="/images/altlaser-logo.png" alt={title} />
        </Link>
        <div className="uk-margin-top">
          {navbarLinks &&
            navbarLinks.map((link, index) => (
              <Link to={link.page} title={link.title} key={index}>
                {link.title}
              </Link>
            ))}
        </div>
      </Panel>
    </Wrapper>
  );
};

const Wrapper = _.div`
  img { max-width: 150px }
`;

const Panel = _.nav`
  background-color: white;

  a {
    text-transform: uppercase;
    display: block;
    line-height: 3rem;
    font-weight: bold;
    color: #213767;

    &:hover { color: #333 }
  }
`;

export default MobileNavbar;
